import axios from 'axios';

export default axios.create({
  // Dev
  baseURL: 'https://virtual-wall-api.mmandb-staging.co.uk',
  // Prod URL: '??',
  headers: {
    'Content-type': 'application/json',
  },
});
